@import "../../styles/Mixins";

.UserSquare {
  position: relative;
  width: 100%;
  max-width: 260px;
  margin: 0 1px 2px 1px;
  cursor: pointer;

  @include xs {
    width: 48%;
  }

  @include sm {
    width: 30%;
  }

  @include md {
    width: 16%;
  }
}

.UserImage {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
}

.UserImageBackground {
  position: absolute;
  width: 100%;
  height: 70px;
  left: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.60) 100%);
}

.Info {
  position: absolute;
  bottom: 14px;
  left: 18px;
  right: 0;
  font-family: 'Source Sans 3', sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #fff;

  .Age {
    font-weight: 400;
    padding: 0 4px;
  }

  .Online {
    height: 24px;
  }
}

.cFlexItemSquare {
    /* flex settings can be basically anything */
    flex: 1 0 auto;
    height: auto;

    &:after {
        content: '';
        display: block;
        padding-top: 100%;
    }
}
