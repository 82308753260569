@import "src/styles/_colors.scss";

.Sidebar {
    display: none;
}

@media (min-width: $breakpointDesktop) {
    .Sidebar {
        display: flex;
        flex-flow: column;
        width: 280px;
        min-height: calc(100vh - 375px);
        margin: 20px 20px 0 0;

        .Menu {
            width: 100%;
            margin-bottom: 44px;

            a {
                display: flex;
                align-items: center;
                width: 100%;
                height: 47px;
                line-height: 47px;
                margin-bottom: 2px;
                border-radius: 4px;
                font-size: 16px;

                i {
                    font-size: 21px;
                    margin: -5px 19px 0 21px;
                }

                .Counter {
                    background: $sidebarColorPurple;
                    border-radius: 100px;
                    margin: 0 15px 0 auto;
                    height: 20px;
                    min-width: 20px;
                    line-height: 20px;
                    text-align: center;
                    color: $colorWhite;
                    font-size: 12px;
                    font-weight: 600;
                }

                &.active {
                    background: $sidebarItemBackgroundBrand;
                    color: $sidebarColorBrand;

                    :hover {
                        color: $sidebarColorBrand;
                    }
                }
            }
        }
    }
}
