@import "src/styles/_colors.scss";

.Agreement {
    .Title {
        margin: 0 0 5px -8px;
        line-height: initial;
        height: initial;
    }

    .SimpleText {
        font-size: 15px;
        margin: 10px -8px;
        line-height: 1.6;
        color: $colorGreyDark;

        a {
            color: $colorBrand;
            cursor: pointer;
        }
    }

    .SaveArea {
        margin: 10px -8px;
        display: flex;
        justify-content: center;
        width: 100%;

        .ButtonsContainer {
            max-width: 320px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
        }

        .Agree, .Disagree {
            padding: 0 42px;
            font-weight: bold;
            margin: 3px 0;

            line-height: 42px;
            border-radius: 21px;
        }
        .Agree {
            background-color: $colorBrand;
            color: $colorWhite;
        }
        .Disagree {
            background-color: $colorGreyLight;
            color: $colorBlack;
        }
    }
}
