@import "src/styles/_colors.scss";

.Loader {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    &.White {
        svg path, svg rect{
            fill: $colorWhite !important;
        }
    }

    svg path, svg rect{
        fill: $colorBrand;
    }
}

