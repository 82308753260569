@import "../styles/Mixins";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;600;800&family=Source+Sans+3:ital,wght@0,400;0,600;1,400&family=Source+Serif+4:ital,opsz,wght@0,8..60,400;1,8..60,300&family=Yeseva+One&family=Poppins:wght@400;500;700&display=swap');

.bodyDark {
    background: #000;
}

.bodyLight {
    background: #fff;
}

.Root {
    display: flex;
    flex-direction: column;
    width: 100%;

    margin: 0;
    font-family: 'Source Sans 3', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;

    div {
        box-sizing: border-box;
    }
}

.Container {
  flex: 1 0 auto;
  height: auto;
  width: 100%;
  aspect-ratio: 9 / 16;
  position: relative;
  box-sizing: border-box;

  &:before {
    content: '';
    display: block;
    padding-top: 177.78%;
  }

  @include sm {
    aspect-ratio: 16 / 9;

    &:before {
      padding-top: 56.25%;;
    }
  }

  .ContainerWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .Content {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      height: 100%;
      align-items: center;
      justify-content: flex-end;
      padding: 32px 0;
      position: relative;
      z-index: 2;

      h1 {
        text-align: center;
        text-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
        max-width: 1120px;
        font-family: Yeseva One;
        font-size: 42px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -1px;
        padding: 0 16px;
        margin: 0;
        color: #fff;
      }

      .AlternativeHeader {
        font-family: Montserrat, sans-serif;
        font-weight: 800;
      }

      .MainSlogan {
        padding: 16px;

        text-align: center;
        text-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
        font-family: Montserrat, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        max-width: 400px;
        color: #fff;
      }
    }

    @include md {
      .Content {
        padding: 100px 0;

        h1 {
          font-size: 64px;
          padding: 0;
        }

        .MainSlogan {
          padding: 24px 0;
        }
      }


    }

    .Background {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 48.44%, rgba(0, 0, 0, 0.50) 100%);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
}

.DesktopVideo {
  display: none;

  @include sm {
    display: block;
  }
}

.MobileVideo {
  display: block;

  @include sm {
    display: none;
  }
}

.PageBackground {
  background: #1E1E1E;
  padding: 64px 0;

    @include md {
        padding: 64px 0 170px 0;
    }
}

.PageBackgroundLight {
  background: #fff;
  padding: 64px 0;

  @include md {
    padding: 0 0 170px 0;
  }
}

.Story {
  flex-direction: column;
  margin-bottom: 64px;

  &.ColumnReverse {
    flex-direction: column-reverse;
  }

  img {
    margin: 0;
    width: 100%;

    @include md {
      margin: 0 0 1px 1px;
    }
  }

  .ImageContainer1 {
    width: 100%;
    max-width: 700px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    box-sizing: border-box;
    justify-content: flex-end;

    @include md {
      align-items: flex-end;
    }

    .ImageItem {
      display: flex;
      justify-content: flex-end;
      width: 50%;
      box-sizing: border-box;
    }

    .ImageItemLong {
      width: 100%;
    }
  }

  .ImageContainer2 {
    display: flex;
    width: 100%;
    max-width: 682px;
    margin-top: 40px;

    .Item {
      box-sizing: border-box;
      width: 50%;
      margin-left: 1px;

      img {
        align-self: baseline;
      }

      .BigImage {
        height: 100%;
        object-fit: cover;
      }

      .LastImage {
        width: 100%;

        @include md {
          width: 75%;
        }
      }
    }
  }


  @include md {
    flex-direction: row;
    padding: 83px 65px;

    &.ColumnReverse {
      flex-direction: row !important;
    }

    .ImageContainer1 {
      .ImageItemLong {
        width: 75%;
      }
    }

    .ImageContainer2 {
      margin-top: 0;
    }
  }
}

.TextContainer {
  max-width: 500px;
  box-sizing: border-box;

  &.Left {
    text-align: right;
    padding: 0 16px;

    @include md {
      padding: 0 40px 0 0;
    }
  }

  &.Right {
    padding: 0 16px;

    @include md {
      padding: 0 0 0 40px;
    }
  }

  .Slogan {
    color: #FFF;
    font-family: 'Source Serif 4', 'sans-serif';
    font-size: 36px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -1px;

    i {
      font-weight: 400;
    }
  }

  .Description {
    margin: 24px 0;
    color: #EACCA9;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }

  .FindMatchButtonArea {
    float: right;
  }
}

mux-player {
  --controls: none;
  --media-object-fit: cover;

  position: absolute;
  inset: 0;
  z-index: 0;
}

mux-player::part(gesture-layer) {
  display: none;
}

.ButtonHeroLighter {
  background-color: #fff !important;
  color: #B77431 !important;
  font-family: Poppins, sans-serif !important;
  font-weight: 700;
}


.MembersArea {
  display: flex;
  flex-direction: column-reverse;

  @include md {
    flex-direction: column;
  }
}


.cFlex {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.cFlexWrap {
    flex-wrap: wrap;
}

.cFlexColumn {
    flex-direction: column;
}

.cFlexSpaceEvenly {
    justify-content: space-evenly;
}

.cFlexItemSquare {
    /* flex settings can be basically anything */
    flex: 1 0 auto;
    height: auto;

    &:after {
        content: '';
        display: block;
        padding-top: 100%;
    }
}
