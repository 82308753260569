@import "src/styles/_colors.scss";

.AreaJoinUs {
    display: block;
    margin: -22px auto 0 auto;
    width: 100%;
    max-width: 335px;
}

.AuthButton {
    width: 160px;
    height: 44px;
    border-radius: 23px;
    background-color: $colorBrand;
    color: #FFF;
    font-size: 18px;
    font-weight: 600;

    &:hover {
        background-color: $colorBrandHover;
        color: #FFF;
    }

    &:disabled {
        background: #eaeaea;
    }
}

.Spacer {
    width: 10px;
}

.SpacerVertical {
    height: 30px;
}

.AuthButtonGrey {
    background-color: #ebebeb;
    color: $colorGreyDark;

    &:hover {
        background-color: #dedede;
        color: $colorBlack;
    }
}

.OptionsScreen {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    .Title {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 35px;
    }

    .Slogan {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 25px;
    }

    .Or {
        font-size: 16px;
        font-weight: bold;
        margin: 18px 0;
    }

    .AuthActions {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .TC {
        margin: 10px 0;
        font-size: 12px;
        letter-spacing: 0.12px;
        color: #3d3d3d;
        text-align: center;
    }
}

.ResendLink {
    text-decoration: underline;
    cursor: pointer;
    margin-left:3px;
    color: $colorBrand;
}

.LoginForm {
    display: block;
    box-sizing: border-box;
    width: 100%;

    .SubmitError {
        width: 100%;
        text-align: center;
        color: #d81656;
        font-weight: bold;
    }

    input {
        width: 100%;
        box-sizing: border-box;
        height: 46px;

        &.Errored {
            border: solid 1px #d81656;
            color: #d81656;
            background-color: rgba(216, 22, 86, 0.05);
        }
    }

    .RegistrationText {
        margin-top: 10px;
        text-align: center;
        color: #3d3d3d;
    }

    .GenderOptions {
        margin-top: 25px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .Spacer {
            width: 15px;
        }

        .Iam {
            font-size: 14px;
            font-weight: bold;
        }
    }

    .PasswordArea {
        position: relative;
        button {
            width: 26px;
            height: 26px;
            position: absolute;
            right: 12px;
            top: 10px;
            font-size: 20px;
            color: #d8d8d8;
        }

        input {
            padding-right: 40px !important;
        }
    }

    .InputLabel {
        font-size: 14px;
        color: #3d3d3d;
        margin: 21px 0 4px 0;
        display: flex;
    }

    .InputError {
        display: flex;
        margin-left: auto;
        color: #d81656;
    }

    .ForgotPassword {
        text-align: left;
        margin: 11px 0 19px;
        font-size: 14px;
        letter-spacing: 0.12px;
        color: #868686;
    }

    .LoginAction {
        width: 100%;
    }

    .Bottom {
        width: 100%;
        text-align: center;
        margin-top: 25px;
    }

    .NewOnTravelgirlsText {
        font-size: 14px;
        letter-spacing: 0.12px;
    }
}

.JoinUsNow {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.14px;
    color: $colorBrand;

    &:hover {
        color: $colorBrandHover;
    }
}
