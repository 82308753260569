@import "src/styles/_colors.scss";

.Wrapper {
    width: 100%;
    flex-direction: column;
}

.Title {
    display: block !important;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.14px;
    text-align: center;
    margin-bottom: 21px;
}

.List {
    flex-wrap: wrap;
    padding: 0 7px;

    .User {
        display: block;
        width: 100%;
        flex: 0 48%;
        padding: 0 3px 14px 3px;

        img {
            width: 100%;
            border-radius: 4px;
        }

        .AreaName {
            align-items: center;
            height: 22px;

            .Name {
                font-weight: bold;
            }

            .Age {
                padding: 0 5px;
            }

            .Online {
                font-size: 7px;
                //Leave green
                color: $colorGreen;
            }
        }

        .Country {
            font-size: 14px;
            color: $colorGrey;
        }
    }
}

@media (min-width: 1000px) {
    .Title {
        br {
            display: none !important;
        }
    }

    .List {
        margin-bottom: 40px;
        justify-content: center;

        .User {
            display: block;
            width: 208px !important;
            height: 268px;
            flex: none;
            border-radius: 5px;
            margin: 5px;

            &:hover {
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
            }
        }
    }
}
