@import "src/styles/_colors.scss";

@media (min-width: $breakpointDesktop) {
    .AppMenu {
        display: none !important;
    }
}

.AppMenu {
    display: block;
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 66px;
    bottom: 0;
    right: 0;
    background: $colorWhite;
    box-shadow: 0 -3px 4px 0 rgba(0, 0, 0, 0.1);

    .NavItems {
        display: flex;
        list-style-type: none;
        padding-top: 12px;
        justify-content: space-between;
        font-size: 12px;

        a, button {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            text-decoration: none;

            i {
                font-size: 24px;
                margin-bottom: 2px;
            }

            .Indicator {
                position: absolute;
                top: -3px;
                right: -3px;
                background: $sidebarColorPurple;
                border: 2px solid $colorWhite;
                border-radius: 100px;
                width: 11px;
                height: 11px;
            }

            @-webkit-keyframes pulse {
                0% { -webkit-transform: scale(1); }
                50% { -webkit-transform: scale(1.4); }
                100% { -webkit-transform: scale(1); }
            }

            @keyframes pulse {
                0% { transform: scale(1); }
                50% { transform: scale(1.4); }
                100% { transform: scale(1); }
            }

            .Pulse {
                animation: pulse 1.5s infinite;
                -webkit-animation: pulse 1.5s infinite;
            }

            &.Selected {
                color: $sidebarColorBrand;
                font-weight: bold;
            }
        }
    }
}

@media all and (display-mode: standalone) {
    .AppMenu {
        height: 80px !important;
    }
}
