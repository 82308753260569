@import "../styles/Mixins";

.Root {
  padding: 0;
  display: flex;
  flex-direction: column;

  @include md {
    padding: 146px 0;

  }
}

.Title {
  font-family: Poppins, sans-serif;
  font-size: 36px;
  color: #000;
  font-weight: 700;
  margin: 0;

  @include md {

  }

}

.SubTitle {
  margin: 12px 0 26px;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  font-family: Poppins, sans-serif;
}

.Button {
  align-self: center;
  background-color: #B77431;
  color: #FFF;
  font-family: Poppins, sans-serif;
  font-weight: 700;
}

