@import "src/styles/_colors.scss";

.PhotoLimit {
    text-align: center;

    h1 {
        font-size: 22px;
        margin: 23px 0;
    }

    .BackgroundImg {
        width: 230px;
    }

    .SimpleText {
        font-size: 16px;
        font-weight: 600;
        max-width: 380px;
        color: $colorGreyDark;
        margin: 0 auto;
    }

    .Line {
        box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.06);
        height: 4px;
        width: 100%;
        position: absolute;
        bottom: 95px;
        left: 0px;
    }

    .Button {
        width: 190px;
        margin: 0 auto;
        margin-top: 45px;
        margin-bottom: -5px;
        cursor: pointer;
    }
}