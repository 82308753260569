@import "src/styles/_colors.scss";

.Area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .Image {
        width: 184px;
        height: 184px;
        border-radius: 100px;
    }

    .Title {
        margin: 29px 0 15px 0;
        font-size: 22px;
        font-weight: bold;
        letter-spacing: -0.4px;
    }

    .Text {
        color: $colorGreyDark;
    }

    .RequestButton {
        margin: 39px 0;
    }

    .RequestSent {
        color: $colorBrand;
        font-weight: 600;
        margin: 39px 0;

    }
}
