@import 'src/styles/_colors.scss';

.Center {
    justify-content: center;
}

.Bold {
    font-weight: 800;
}

.Landing {
    div {
        display: flex;
        color: $colorBlack;
    }

    h2 {
        margin-top:40px;
    }

    h3 {
        font-size: 24px;
        margin: 15px 0;
    }

    h4 {
        font-size: 16px;
        text-align: center;
        font-weight: normal;
        width: 330px;
        margin: 0 auto 20px;
    }

    .MobileHeader {
        padding-top:25px;
        flex-direction: column;

        .HeaderLogo {
            justify-content: center;
            img {
                width: 200px;
            }
        }

        .HeaderTitle {
            font-size: 24px;
            text-align: center;
            color: #000;
            padding: 0 12px;
        }
    }

    .MobileFooter {
        .FooterInner {
            background: $tgButterfly no-repeat;
            background-color:#f5f5f5;
            background-size: cover;
            flex-direction: column;
            width: 100%;

            .Logos {
                flex-direction: column;
                margin: 20px auto 0;
                .GoogleBadge {
                    width: 166px;
                }
            }

            .Secure {
                display: flex;
                justify-content: center;
                align-items: center;
                color: $colorGreyDark;
                margin: 30px 0 45px 0;

                .Lock {
                    font-size: 20px;
                }

                .Text {
                    font-size: 12px;
                    font-weight: bold;
                    margin: 0 8px;
                }

                .CreditCards {
                    margin: 0 2px;
                    font-size: 24px;
                }
            }

            .Social {
                width: 166px;
                justify-content: space-between;
                margin: 0 auto;
                margin-bottom:30px;
            }
            .Links {
                justify-content: space-evenly;
                width: 90%;
                margin: 3px auto;
                a {
                    font-size:14px;
                    color: $colorGrey;
                }
            }
            .AllRights {
                font-size: 14px;
                color: $colorGrey;
                text-align: center;
                margin: 15px 10px 30px 10px;

            }
        }
    }

    .MainBackgroundAndSlogan {
        min-height: 100vh;
        display: block;
        margin-bottom: 25px;
        .Info {
            flex-direction: column;

            .ActiveGirlsTitle {
                order: 2;
            }

            .InfoButtons {
                order: 1;
                flex-direction: column;
            }
        }
    }

    .Padding {
        padding: 0 12px;
    }

    .SimpleText {
        color: $colorGreyDark;
        font-size: 14px;
    }

    .SubTitle {
        color: $colorGreyDark;
        font-size: 15px;
    }

    .VideoContainer {
        position: relative;
        margin: 25px 0 20px 0;

        .VideoPlayBtn {
            background: url(../../../assets/video-play.png);
            height: 42px;
            width: 42px;
            background-size: 100% 100%;
            cursor: pointer;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }


    .Login {
        display: flex;
        width: 90%;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        margin-top: -220px;

        .Title {
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 35px;
        }

        .Slogan {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 25px;
        }

        .Or {
            font-size: 16px;
            color: $colorWhite;
            font-weight: bold;
            margin: 12px 0;
        }

        .AuthActions {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        .TC {
            margin: 30px 0;
            font-size: 14px;
            letter-spacing: 0.12px;
            color: #3d3d3d;
            text-align: center;
        }
        .AuthButton {
            width: 160px;
            height: 44px;
            border-radius: 23px;
            background-color: $colorBrand;
            color: #FFF;
            font-size: 18px;
            font-weight: 600;

            &:hover {
                background-color: $colorBrandHover;
                color: #FFF;
            }

            &:disabled {
                background: #eaeaea;
            }
        }

        .Spacer {
            width: 10px;
        }

        .AuthButtonGrey {
            background-color: #ebebeb;
            color: $colorGreyDark;

            &:hover {
                background-color: #dedede;
                color: $colorBlack;
            }
        }
    }

    .LandingImg {
        width: 100%;
    }

    .Title {
        font-size: 22px;
        letter-spacing: 0.19px;
        padding: 0 20px 10px 20px;
    }

    .Slogan {
        font-size: 26px;
        font-weight: 800;
        letter-spacing: 0.23px;
        padding: 0 20px 25px 20px;
    }

    .OrCopy {
        margin: 16px 0 10px 0;
        font-family: Georgia;
        font-style: italic;
    }

    .videoPlayImg {
        width: 100%;
        margin: 0;
    }

    .JoinUs {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 34px;
    }

    .AppImg {
        width: 100%;
        margin-bottom: 15px;
    }

    .BottomSpacer {
        margin-bottom: 50px;
    }
}

.emptyBackground {
    background:none !important;
    background-color: #f5f5f5 !important;
}
