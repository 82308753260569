.Container {
    width: 100%;

    border-radius: 4px;
    border: solid 1px #ebebeb;
    background-color: #f6f6f6;

    margin-bottom: 50px;
}

.Title {
    font-weight: bold;
    letter-spacing: -0.26px;
    margin: 17px 0 14px 21px;
}

.Button {
    width: 100%;
}

.List {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-flow: row;
    overflow: hidden;
    margin-bottom: 8px;

    img {
        margin-left: 6px;
        width: 50px;
        height: 50px;
        border-radius: 4px;
    }

    .GoButton {
        width: 40px;
        height: 40px;
        background: #FFF;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.21);
        border-radius: 100px;
        position: absolute;
        right: 4px;
        top: 5px;

        i {
            line-height: 40px;
            margin: 0 0 0 4px;
            font-size: 20px;
        }
    }

}
