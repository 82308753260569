@import "src/styles/_colors.scss";

.AddTrip {
    width: 100%;
    display: block;
    text-align: center;

    h1 {
        height: 24px;
        margin: 10px 0 25px 0;
        font-size: 22px;
        font-weight: bold;
    }

    .Introduction {
        color: $colorGreyDark;
        font-size: 18px;
        font-weight: 600;
    }

    .label {
        margin-bottom: 5px;
    }

    .Error {
        color: $colorError;
        margin-top: 5px;
    }

    .Errored input {
        border: solid 1px $colorError;
        color: $colorError;
        background-color: rgba(216, 22, 86, 0.05);

        &:focus {
            border: solid 1px $colorError !important;
        }
    }

    .SuccessMessage {
        color: $colorBrand;
    }

    .InputContainer {
        position: relative;

        .InputIcon {
            position: absolute;
            right: 10px;
            top: 7px;
            font-size: 25px;
        }
    }

    .LocationRow {
        margin: 15px 0 40px 0;
        text-align: left;
    }

    .DateRow {
        margin: 15px 0 30px 0;
        text-align: left;
        display: flex;
        justify-content: space-between;

        .DateField {
            width:45%;
        }
    }

    .CheckboxContainer {
        text-align: left;

        input {
            width: 20px;
            height: 14px;
            vertical-align: bottom;
        }

        label {
            font-size: 12px;
        }
    }


    .SaveArea {
        width: 100%;
        margin: 0 auto;

        .Save {
            padding: 0 42px;
            font-weight: bold;
            color: $colorWhite;

            line-height: 42px;
            border-radius: 21px;
            background-color: $sidebarColorPurple;
        }
    }
}

.addTripAutocomplete {
    max-width: none!important;

    input {
        padding: 0 10px!important;
    }

    i {
        display: none;
    }
}
