@import "_colors.scss";
@import "font/travelgirls/style.css";
@import "Inputs";
@import "LightboxCustom.scss";

@media screen and (max-device-width: 480px),
screen and (-webkit-min-device-pixel-ratio: 2),
screen and (device-width: 768px) {
    body, html {
        -webkit-text-size-adjust:none;
    }
}

div {
    box-sizing: border-box;
    display: block;
}

a {
    color: $colorGreyDark;
    text-decoration: none;

    &:hover {
        color: $colorBlack;
    }
}

button {
    font: inherit;
    color: $colorGreyDark;
    text-decoration: none;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    box-sizing: border-box;
    text-shadow: none;
    display: inline-block;
    cursor: pointer;
    outline: none !important;

    &:hover {
        color: $colorBlack;
    }
}

button:hover {
    color: $colorBlack;
}

.BrDesktop {
    display: none;
}

@media (min-width: $breakpointDesktop) {
    .BottomSpacer {
        display: none !important;
    }

    .BrMobile {
        display: none;
    }

    .BrDesktop {
        display: block;
    }
}

.BottomSpacer {
    display: block;
    width: 100%;
    height: 70px;
}

@media all and (display-mode: standalone) {
    .BottomSpacer {
        height: 85px  !important;
    }
}

.dflex {
    display: flex;
    align-items: center;
}

.CenteredText {
    text-align: center;
}

.HiddenMobile {
    @media (max-width: $breakpointDesktop) {
        display: none !important;
    }
}

.CustomScroll {
    &::-webkit-scrollbar {
        width: 10px !important;
        height: 0;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $colorGreyLight !important;
        border-radius: 20px !important;
        border: 3px solid transparent !important;
        background-clip: content-box !important;
    }
}

.MessageAboutEmptyList {
    padding: 10px 12px;
    text-decoration: underline;
}
