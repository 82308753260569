@import "src/styles/_colors.scss";

.Menu {
    padding: 18px 1px;
    z-index: 10;

    &.NotificationMenu {
        padding: 9px;

        .ArrowUp {
            display: none;
        }

        .Title {
            display: none;
        }

        .EmptyContent {
            font-size: 14px;
            color: #3d3d3d;
            margin-left: 12px;
        }

        .NotificationItem {
            background-color: #f6f6f6;
            color: $colorGreyDark;
            padding: 12px;
            border-radius: 5px;
            margin-top:10px;
            position: relative;

            .CloseBtn {
                position: absolute;
                top: 8px;
                right: 10px;
                cursor: pointer;
            }

            .NotificationContent {
                width: 90%;
            }

            .NotificationActions {
                color: $colorBrand;
                font-weight: 600;
                padding: 4px 10px;
                border-radius: 3px;
                background-color: $colorBrandLighter;
                height: 30px;
                width: fit-content;
                margin: 5px 0 0 0;
            }
        }
    }

    .ArrowUp {
        position: absolute;
        right: 50px;
        top: -10px;
        height: 10px;
        width: 10px;

        &:before, &:after {
            content: '';
            position: absolute;
            top: -10px;
            left: 0;
            border: 10px solid transparent;
            border-bottom-color: $colorGreyLight;
        }

        &:after {
            left: 0;
            top: -9px;
            border: 10px solid transparent;
            border-bottom-color: $colorWhite;
        }
    }

    .NewItem {
        color: $colorBrand;
        margin-left: 5px;
        font-size: 12px;
        font-weight: bold;
        margin-top: -15px;
    }

    .Line {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        color: #3d3d3d;
        width: 100%;
        height: 45px;
        margin-bottom: 3px;
        padding: 0 18px;

        &:hover {
            background: #f6f6f6;
        }

        &.IsDisabled {
            opacity: 0.4;
        }

        i {
            font-size: 21px;
            font-weight: bold;
            margin: 0 15px 0 0;
        }

        .Toggle {
            margin-left: auto;

            button {
                height: 20px;
                width: 45px;
                margin-bottom: 1px;
                margin-left: 1px;

                &:hover {
                    background-color: #d8d8d8;
                }
                div {
                    width: 15px !important;
                    height: 15px !important;
                }
            }
        }
    }

    .NewItemGold {
        color: #ea9c0d;
        margin-left: 3px;
        font-size: 11px;
        font-weight: bold;
        margin-top: -15px
    }

    .PremiumMember {
        color: $sidebarColorPurple;
        font-size: 12px;
        font-weight: bold;
    }

    a, button {
        display: flex;
        align-items: center;
        width: 100%;
        height: 46px;
        line-height: 46px;
        margin-bottom: 8px;
        padding: 0 16px;

        &:hover {
            background: #f6f6f6;
        }

        span {
            font-size: 21px;
            margin: 0 16px 0 0;
        }

        &.active {
            background: $sidebarItemBackgroundBrand;
            color: $sidebarColorBrand;

            :hover {
                color: $sidebarColorBrand;
            }
        }
    }
}

// Desktop css override
@media (min-width: $breakpointDesktop) {
    .Menu {
        position: absolute;
        right: 0;
        top: 52px;
        width: 300px;
        background: $colorWhite;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.13), 0 0 4px 0 rgba(0, 0, 0, 0.19);
        border-radius: 4px;

        &.NotificationMenu {
            right: -50px !important;
            width: 400px !important;

            .ArrowUp {
                display: block;
                right: 76px !important;
            }

            .Title {
                display: block;
                font-size: 16px;
                font-weight: bold;
                margin: 5px 0 0 12px;
            }

            .NotificationItem {
                .NotificationContent {
                    width: 320px;
                }
            }
        }
    }
}
