@import "src/styles/_colors.scss";

.Layout {
    //min-height: 100vh;
}

@media (min-width: $breakpointDesktop) {
    .Layout {
        display: block;
        width: 100%;
        margin: 68px auto 0 auto;
        background: $colorWhite !important;

        &.LayoutWithDesignSwitchLine {
            margin: 112px auto 0 auto !important;
        }
    }

    .DesktopSidebarContentWrapper {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        min-height: 400px;
    }
}

