@import 'src/styles/_colors.scss';

.gdprLine {
    z-index:99999;
    position:fixed;
    background:#3C3C3C;
    left:0;
    right:0;
    bottom:0;
    width:100%;
    color:#FFF;
    font-size:12px;
    text-align:left;

    .insideLine {
        padding:8px 20px;
    }

    .area-gdpr-text {
        display: inline-block;
        text-align: left;
        min-height: 32px;
        line-height: 24px;
        margin-bottom: 10px;
    }

    .area-gdpr-buttons {
        text-align: center;

        .link {
            display:inline-block;
            border-radius:4px;
            background:#f5f5f5;
            font-size:14px;
            font-weight: 600;
            color:#4a4a4a;
            padding:6px 20px;
            cursor:pointer;
            text-decoration:none;
        }

        .closeBtn {
            display:inline-block;
            vertical-align:middle;
            margin-left:20px;
            margin-top: -9px;
            width:34px;
            height:34px;
            font-size:30px;
            color:#FFF;
            cursor:pointer;
            text-decoration:none;
        }
    }
}

@media (min-width: $breakpointDesktop) {
    .gdprLine {
        .area-gdpr-text {
            line-height: 32px;
            margin-bottom: 0px;
        }

        .area-gdpr-buttons {
            display: inline-block;
            float: right;
            margin-top: 0px;
        }
    }
}
