@import "../../styles/Mixins";

.Container {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    flex-wrap: wrap;

    h2 {
        font-family: 'Source Serif 4', serif;
        color: #FFF;
        text-align: center;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 0;
        margin: 14px 0 32px 0;

        .italic {
            font-style: italic;
            font-weight: 300;
        }
    }
}

.LinkSlogan {
  margin: 16px 0;
  text-align: center;
  font-family: Montserrat, serif;
  color: #EACCA9;
  font-weight: 400;
  line-height: 160%;
  font-size: 16px;

  a {
    text-decoration: underline;
    color: #EACCA9;
    margin-left: 5px;
  }
}

.LinkSloganAlternate {
  margin: 16px 0;
  text-align: center;
  font-family: Poppins, serif;
  color: #000;
  font-weight: 500;
  line-height: 160%;
  font-size: 18px;

  a {
    margin-left: 4px;
    text-decoration: none;
    color: #B77431;
    font-weight: 600;
  }
}

.UsersArea {
  width: 100%;
  padding: 0 1px 30px 1px;
  box-sizing: border-box;
}

.Title {
  padding: 0 16px;

  @include md {
    padding-top: 40px;
  }
}

.cFlex {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.cFlexWrap {
    flex-wrap: wrap;
}

.cFlexColumn {
    flex-direction: column;
}

.cFlexSpaceEvenly {
    justify-content: space-evenly;
}
