.Area {
    margin: 5px auto 0 auto;
    width: 100%;
    text-align: center;

    h3 {
        color: #d81656;
    }

    img {
        width: 56px;
    }

    button {
        margin: 15px 0;
    }

    p {
        color: #3d3d3d;
        text-decoration: underline;
        font-size: 14px;
        cursor: pointer;
    }
}
