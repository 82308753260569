.FbButton {
    width: 100%;
    max-width: 330px;
    height: 44px;
    border-radius: 23px;
    background-color: #3b65b8;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    margin-top: 10px;

    &:hover {
        color: #FFF;
        background-color: #345bac;
    }
}

.Disabled {
    opacity: 0.6;
}
