@import "../styles/Mixins";

.Root {
  display: flex;
  flex-direction: column;
  padding: 0 16px 64px;

  @include md {
    padding: 146px 10%;

    flex-direction: row;
    justify-content: center;
  }
}

.Title {
  font-family: Poppins, sans-serif;
  color: #000;
  font-weight: 700;
  margin: 0 0 18px;
  font-size: 32px;

  @include md {
    font-size: 36px;
    width: 345px;
    margin: 0 82px 0 0;
  }
}

.SubTitle {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  margin: 0 0 24px;

  @include md {
  width: 400px;
  }
}

.Button {
  float: left;
  background-color: #B77431;
  color: #FFF;
  font-family: Poppins, sans-serif;
  font-weight: 700;
}
