@import "_colors.scss";

/* The container */
.Checkbox {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding-left: 24px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /* Hide the browser's default checkbox */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .Checkmark {
        position: absolute;
        top: 1px;
        left: 0;
        height: 14px;
        width: 14px;
        background: #FFF;
        border-radius: 2px;
        border: 1px solid #d8d8d8;
    }
}



/* On mouse-over, add a grey background color */
.Checkbox:hover input ~ .Checkmark {
    border: 1px solid $colorGrey;
}

/* When the checkbox is checked, add a blue background */
.Checkbox input:checked ~ .Checkmark {
    background-color: $colorBrand;
    border: 1px solid $colorBrand;

}

/* Create the checkmark/indicator (hidden when not checked) */
.Checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.Checkbox input:checked ~ .Checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.Checkbox .Checkmark:after {
    left: 4px;
    top: 0;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
