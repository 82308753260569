.Container {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 63px 15px;
  text-align: center;
  color: rgba(163, 163, 163, 0.50);
  background: #000;

  .Links {
    margin-bottom: 20px;
  }

  a {
    color: rgba(163, 163, 163, 0.50);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.ContainerLight {
  background: #fff;
  color: #76777A;
}


.LinksDarker {
  a {
    color: #76777A;
  }
}
