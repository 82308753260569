@import 'src/styles/_colors.scss';

.Header {
    &.HeaderWithDesignSwitchLine {
        top: 44px !important;
    }

    &.AlwaysVisible {
        display: block;
    }

    display: none;

    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.06);
    position: fixed;
    top: 0;
    width: 100%;
    background: $colorWhite;
    z-index: 10;
}

.Wrapper {
    width: 100%;
    height: 48px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;

    .LinkLogo {
        height: 29px;
        width: 160px;
    }
}

.Right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    a.User {
        color: #000;
    }

    .User {
        font-weight: bold;
        display: flex;
        align-items: center;

        img {
            width: 30px;
            height: 30px;
            border-radius: 100px;
            margin-right: 14px;
        }

        .name {
            margin-right: 10px;
        }
    }

    .Notifications {
        display: none;
    }

    .Conversations {
        display: none;
    }
}

.MergedLogo {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (min-width: $breakpointDesktop) {
    .Header {
        display: block;
    }

    .Wrapper {
        height: 68px;
        padding: 0 31px;
    }

    .Right {
        .Notifications {
            display: block;
            position: relative;
            margin-right: 20px;

            .NotificationButton {
                padding: 15px;

                &.NotificationButtonInactive {
                    color: $colorGreyVeryLight;
                    cursor: default;

                    :hover {
                        color: $colorGreyVeryLight;
                    }
                }
            }
        }

        .Conversations {
            display: block;
            font-size: 28px;
            text-decoration: none;
            padding: 10px;
            margin-right: 10px;
            position: relative;
        }
    }

    .UnreadCounter {
        position: absolute;
        color : $colorWhite;
        background: $sidebarColorPurple;
        border: solid 2px $colorWhite;

        padding: 1px 6px;

        font-size: 12px;
        font-weight: 600;
        border-radius: 100px;
        left: 24px;
        top: 6px;
    }

    .ActiveIcon {
        color: $colorBrand !important;
    }
}

.TfLogo {
    width: 160px;
    height: 29px;
    margin-right: 10px;
}
