@font-face {
  font-family: 'travelgirls-icons';
  src:  url('fonts/travelgirls-icons.eot?kef4bf');
  src:  url('fonts/travelgirls-icons.eot?kef4bf#iefix') format('embedded-opentype'),
    url('fonts/travelgirls-icons.ttf?kef4bf') format('truetype'),
    url('fonts/travelgirls-icons.woff?kef4bf') format('woff'),
    url('fonts/travelgirls-icons.svg?kef4bf#travelgirls-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.tg-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'travelgirls-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tg-logo:before {
  content: "\e914";
}
.icon-filter:before {
  content: "\e917";
}
.icon-chat:before {
  content: "\e916";
}
.icon-conversations:before {
  content: "\e918";
}
.icon-inbox:before {
  content: "\e906";
}
.icon-plane:before {
  content: "\e908";
}
.icon-trips-map:before {
  content: "\e915";
}
.icon-profile-background:before {
  content: "\e900";
}
.icon-group-profile:before {
  content: "\e903";
}
.icon-profile:before {
  content: "\e90a";
}
.icon-star-full:before {
  content: "\e904";
}
.icon-star:before {
  content: "\e921";
}
.icon-support:before {
  content: "\e91a";
}
.icon-diamond:before {
  content: "\e90b";
}
.icon-private-photos-lock:before {
  content: "\e91b";
}
.icon-smile:before {
  content: "\e91c";
}
.icon-delete:before {
  content: "\e902";
}
.icon-send:before {
  content: "\e905";
}
.icon-logout:before {
  content: "\e907";
}
.icon-camera:before {
  content: "\e909";
}
.icon-search:before {
  content: "\e913";
}
.icon-close-small:before {
  content: "\e91d";
}
.icon-close-big:before {
  content: "\e901";
}
.icon-tick:before {
  content: "\e91e";
}
.icon-tick-right:before {
  content: "\e91f";
}
.icon-tick-down:before {
  content: "\e90c";
}
.icon-tick-back:before {
  content: "\e90f";
}
.icon-select-more:before {
  content: "\e920";
}
.icon-dots-more:before {
  content: "\e90e";
}
.icon-info:before {
  content: "\e90d";
}
.icon-location:before {
  content: "\e910";
}
.icon-lock:before {
  content: "\e911";
}
.icon-online:before {
  content: "\e912";
}
.icon-eye:before {
  content: "\e922";
}
.icon-bell:before {
  content: "\e923";
}
.icon-more:before {
  content: "\e924";
}
.icon-photo-camera:before {
  content: "\e925";
}
.icon-private-photos:before {
  content: "\e919";
}
.icon-lock-2:before {
  content: "\e926";
}
.icon-master-card:before {
  content: "\e927";
}
.icon-visa:before {
  content: "\e928";
}
.icon-stop:before {
  content: "\e929";
}
.icon-heart:before {
  content: "\e92a";
}
.icon-switch:before {
  content: "\e92b";
}
.icon-hide:before {
  content: "\e92c";
}
.icon-exclamation-mark:before {
  content: "\e92d";
}
.icon-translate:before {
  content: "\e92e";
}
.icon-pin:before {
    content: "\e92f";
}
