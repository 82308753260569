@import "src/styles/_colors.scss";

.Area {
    margin: -22px auto 0 auto;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    h1 {
        font-size: 16px;
        font-weight: 600;
        margin: 3px 0 0 0;
        padding: 0;
    }

    h2 {
        text-align: center;
        font-size: 18px;
        font-weight: normal;
        margin: 25px 0 0 0;
        padding: 0;
    }

    h3 {
        margin-top: 1px;
        font-weight: 600;
        font-size: 16px;
    }

    .WhiteLink {
        color: $colorWhite;
    }

    .ImageArea {
        margin: 37px 0 31px 0;
        width: 300px;
        height: 172px;
        position: relative;

        &.SmallArea {
            width:265px;
        }

        .ImgOthers {
            position: absolute;
            right: 0;
            top: 0;
            height: 172px;
            width: 163px;
        }

        .ImgUser {
            width: 98px;
            height: 98px;
            border-radius: 100px;
            position: absolute;
            left: 0;
            top: 37px;
            background-color: $colorGreyLight;
            z-index: 2;
        }

        .ImgUserOutline {
            border: 5px solid $colorWhite;
            outline: 2px solid $colorGreyLight;
        }

        .ImgUserToContact {
            border: 5px solid $colorWhite;
            left: 154px !important;
            z-index: 2;
        }

        .Line, .LineFemale {
            position: absolute;
            left: 105px;
            top: 85px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #bcbcbc;
            width: 44px;
            height: 0;
            z-index: 2;
        }

        .LineFemale { 
            left: 109px;
            top: 90px;
        }
    }

    .Upgrade {
        width: 100%;
        max-width: 276px;
        margin: 10px 0 37px 0;
        text-align: center;
        padding: 0 !important;
    }

    .Text, .TextSmall {
        text-align: center;
        max-width: 326px;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: -0.25px;
        color: $colorGreyDark;

        &.BottomText {
            margin-top: 20px;
            font-weight: normal;
        }
    }

    .TextSmall {
        font-size: 12px;
        font-weight: normal;
        color: $colorGrey;
    }

    .CancelText {
        font-size: 13px;
        margin-top: -3px;
        margin-bottom: 15px;
    }

    .OldPrice {
        text-decoration: line-through;
        font-size: 18px;
        font-weight: normal;
        color: #000;
    }

    .NewPrice {
        font-size: 20px;
        font-weight: bold;
        color: #000;
        margin-bottom: 15px;
    }
}

// Desktop css override
@media (min-width: $breakpointDesktop) {

    .Area {
        .Text, .TextSmall { 
            max-width: 465px;
        }
    }
}
