.Main {
    padding: 0;
    width: 100%;
    justify-content: center;

    display: flex;
    position: relative;


    .LoadingContainer {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        background: #FFF;
        z-index: 2;
    }
}
