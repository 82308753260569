@import "src/styles/_colors.scss";

.MainContent {

}

.StaticPagesWrapper {
    padding: 0 20px;
    line-height: 24px;
}

@media (min-width: $breakpointDesktop) {
    .MainContentSpacer20 {
        margin-top: 20px;
    }

    .MainContent {
        display: flex;
        width: $widthDesktopSidebarContent;
        min-height: 300px;
    }

    .WithoutSidebar {
        width: $widthDesktopContent !important;
    }

    .FullWidth {
        width: 100% !important;
    }
}
