@import "src/styles/_colors.scss";

@media (min-width: $breakpointDesktop) {
    .PhotoUploadInfo {
        font-size: 16px !important;

        p {
            margin: 0 0 30px 0 !important;
        }

        h1 {
            font-size: 22px !important;
        }

        .List {
            flex-direction: row !important;
            flex-wrap: wrap;
            margin: 20px 0 !important;
            width: 450px;

            li {
                height: 30px;
            }
        }

        .Actions {
            flex-direction: row !important;

            .DoNotShow {
                margin: 0 50px 0 0 !important;
            }
        }
    }
}

.PhotoUploadInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;

    h1 {
        font-size: 18px;
        font-weight: bold;
        margin: 13px 0;
    }

    p {
        line-height: 1.63;
        text-align: center;
        margin: 0 0 20px 0;
    }

    .List {
        list-style-type: none;
        display: flex;
        flex-direction: column;

        margin: 8px 0;
        padding: 0;

        li {
            flex: 0 50%;
        }

        i {
            font-size: 12px;
            color: #d81656;
            margin: 0 10px
        }
    }

    .Actions {
        display: flex;
        flex-direction: column;
        align-items: center;

        .DoNotShow {
            margin: 0 0 10px 0;
            font-size: 14px;

            label {
                align-items: center;
            }
        }
    }
}


