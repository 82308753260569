@import "src/styles/_colors.scss";
$breakpointForDesktopPadding: calc($breakpointDesktop + 60px);

.Footer {
    display: none;
}

@media (min-width: $breakpointDesktop) {
    .Footer {
        display: block;
        width: 100%;
        background-color: #f6f6f6;
        font-size: 14px;

        .AllRights {
            margin-top: 26px;
            border-top: 1px solid #d8d8d8;
            color: #868686;
            text-align: center;
            padding: 22px 0;

            a {
                color: #868686;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .FooterBackground {
        max-width: $widthDesktopMax;
        margin: 0 auto;

        background: $tgButterfly no-repeat;
        background-size: auto 280px;
        background-position-x: 60px;
        background-position-y: -70px;

        .FooterInner {
            background: $tgButterfly no-repeat right bottom;
            background-size: 500px auto;
            background-position-y: -40px;
            max-width: $widthDesktopContent;
            margin: 0 auto;
            padding: 27px 0 0 0;

            @media (max-width: $breakpointForDesktopPadding) {
                max-width: calc($widthDesktopContent - 100px);
            }

            ul {
                margin: 0;
                padding: 0;
                width: 250px;
                list-style-type: none;

                li {
                    padding-bottom: 20px;

                    &.title {
                        font-weight: 600;
                    }
                }
            }

            .Logos {
                margin-left: auto;

                div {
                    text-align: right;
                }
            }

            .Secure {
                display: flex;
                align-items: center;
                color: $colorGreyDark;

                .Lock {
                    font-size: 20px;
                }

                .Text {
                    font-size: 12px;
                    font-weight: bold;
                    margin: 0 8px;
                }

                .CreditCards {
                    margin: 0 2px;
                    font-size: 30px;
                }
            }
        }
    }

    .emptyBackground {
        background:none !important;
    }

}
