@import "src/styles/_colors.scss";

.MobileChoosePlan {
    margin: 8px 11px;
    border-radius: 9px;
    background-color: rgba(118, 118, 128, 0.12);
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0 2px;

    .Item {
        width: 50%;
        text-align: center;

        font-size: 13px;
        font-weight: 600;
        line-height: 28px;

        &.Active {
            background: #FFF;
            border-radius: 7px;
        }
    }
}

.Area {
    display: none;

    &.AlwaysVisible {
        display: flex;;
    }

    &.MobileVisible {
        display: flex;
    }

    justify-content: center;
    margin: 23px 0 20px 0;

    .Plan {
        position: relative;
        display: flex;
        width: 100%;
        height: fit-content;
        min-height: 321px;
        padding: 12px;
        border-radius: 11px;
        border: 2px solid #e4e4e4;
        margin: 0 14px;
        flex-flow: column;
        align-items: center;

        .PlanHeader {
            min-height: 149px;
            color: $colorBlack;
            margin-bottom: 20px;
            padding-bottom: 15px;
            text-align: center;
            width: 100%;
            border-radius: 2px;
            background: rgb(216,216,216,0.3);

            h2 {
                color: #0ab734;
            }

            .Gold {
                color: #ea9c0d;
            }

            .Header {
                display: flex;
                width: 266px;
                justify-content: space-around;
                margin: 15px auto;
                font-size: 28px;
                font-weight: 800;
                img {
                    width: 45px;
                }
            }

            .DayPrice {
                font-size: 16px;
                .Big {
                    font-weight: bold;
                    font-size: 32px;
                }
            }

            .MonthPrice {
                font-size: 16px;
                font-weight: 600;
            }

            .Discount {
                text-decoration: line-through;
            }
        }

        .OtherPrice {
            height: 49px;
            color: $colorBlack;
            margin-bottom: 5px;
            text-align: center;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            border-radius: 2px;
            background: rgb(216,216,216,0.3);

            .Bold {
                font-weight: bold;
                font-size: 18px;
            }

            .CtaButton {
                display: flex;
                box-sizing: border-box;
                width: 118px;
                height: 34px;
                padding: 0 10px;
                border-radius: 21px;
                align-items: center;
                justify-content: center;
                background-color: rgba(10, 183, 52, 0.17);
                cursor: pointer;
                transition: 0.1s ease all;

                font-size: 14px;
                font-weight: bold;
                color: #0ab734;

                &:hover {
                    box-shadow: 0 0 10px -5px #666;
                }
            }
        }

        .Title {
            font-size: 24px;
            font-weight: bold;
            margin: 13px;
        }

        .SubTitle {
            width: 240px;
            text-align: center;
            font-size: 16px;
        }

        .AdvancedTitle {
            width: 180px;
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            margin-bottom: 30px;
        }

        .Price {
            font-size: 18px;
            font-weight: bold;
            margin: 23px 0 12px 0;

            .OldPrice {
                font-weight: normal;
                text-decoration: line-through;
            }
        }

        .DiamondButton {
            padding: 5px 31px !important;
            border-radius: 21px;
            font-size: 16px;
            background-image: linear-gradient(100deg, #fdbb36, #ff7632);
            cursor: pointer;
            transition: 0.1s ease all;
            a {
                background-image: linear-gradient(100deg, #fdbb36, #ff7632);
                font-weight: bold;
            }
            &:hover {
                box-shadow: 0 0 10px -2px #666
            }
        }

        .AdvancedButton {
            height: 44px;
            margin: 0 0 23px 0;
            border-radius: 21px;
            background-color: #0ab734;
            cursor: pointer;
            transition: 0.1s ease all;
            a {
                background-color: #0ab734;
                font-weight: bold;
            }
            &:hover {
                box-shadow: 0 0 10px -2px #666
            }
        }

        .Benefits {
            width: 100%;
            margin-top: 26px;

            .BenefitItem {
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;

                .Image {
                    width: 60px;
                    height: 60px;
                    img {
                        width: 60px;
                    }
                }

                .Info {
                    width: 240px;

                    .InfoTitle {
                        font-size: 18px;
                        font-weight: bold;
                    }

                    .Text {
                        font-size: 15px;
                        color: $colorGreyDark;
                    }
                }
            }
        }

        .NextStep {
            height: 49px;
            line-height: 49px;
            text-align: center;
            width: 100%;
            font-size: 16px;
            cursor: pointer;
            font-weight: bold;
            color: $colorBlack;
            border-radius: 4px;
            background-color: #ffcb0d;
        }
    }
}

.MobileBottomSlogan {
    width: 100%;
    display: flex;
    padding: 0px 35px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: bold;

    .Cards {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 82px;
    }

    img {
        width: 36px;
    }
}

@media (min-width: $breakpointDesktop) {
    .MobileChoosePlan {
        display: none;
    }

    .MobileBottomSlogan {
        display: none;
    }

    .Area {
        &.MobileVisible {
            display: none;
        }

        display: flex;

        .Plan {
            display: flex;
            width: 360px;

            &.Selected {
                border: solid 4px #868686;
                padding: 10px;
            }

            &.Diamond {
                border: 2px solid #ffbd44;
                &.Selected {
                    border: solid 4px #ffbd44;
                }
            }
        }
    }
}

@keyframes shake {
    0%, 100% {transform: translateX(0);}
    25%, 75% {transform: translateX(-5px);}
    50% {transform: translateX(5px);}
}

.ErrorMessage {
    padding: 10px;
    color: $colorError;
    font-size: 14px;
    font-weight: bold;
    animation: shake 0.4s ease-in-out;
    text-align: center;
}

.SuccessMessage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $colorGreen;
    font-size: 1.5em;

    span {
        padding: 20px;
    }
}

.LoadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 11px;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}
