@import "src/styles/_colors.scss";

.Element {
    padding: 0;
    margin: 0;
    position: relative;
    display: flex;

    width: 100%;
    max-width: 400px;

    .Inner {
        display: flex;
        width: 100%;
    }

    input {
        width: 100%;
        font-size: inherit;
        font-family: inherit;
        padding: 0 30px;
        line-height: 22px;
        border: solid 1px #CCC;
    }

    .LocationIcon {
        position: absolute;
        width: 30px;
        left:0;
        top: 0;
        bottom: 0;
        font-size: 20px;
        color: $colorGrey;

        display: flex;
        align-items: center;
        justify-content: center;

        i {
            margin-left: 5px;
        }
    }

    .CloseIcon {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 30px;
        font-size: 14px;

        i {
            margin-right: 5px;
        }
    }

    .Results {
        display: block;
        position: absolute;
        top: 36px;
        left: 0;
        width: 100%;
        box-shadow: 0 2px 8px rgba(0,0,0,.6);
        z-index: 10000;


        .Item {
            width: 100%;
            background: #fff;
            border-bottom: 1px solid #f5f5f5;
            padding: 10px 15px;
            font-size: 16px;
            color: #000;
            cursor: pointer;
            transition: all .3s ease-in;
            text-overflow: ellipsis;
            max-height: 39px;
            white-space: nowrap;
            overflow: hidden;
            text-align: left;

            &:last-of-type {
                border-bottom: 0
            }
        }
    }
}
