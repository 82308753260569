@import "src/styles/_colors.scss";

.Location {
    width:100%;
    display: block;

    .LocationBackground {
        position:absolute;
        margin: -35px 0 0 -35px;
        z-index: 1;
        img {
            width:100%;
        }
    }

    .ContentContainer {
        margin-top: 140px;
        text-align: center;
    }

    .SimpleText {
        font-size: 15px;
        width:290px;
        margin: 5px auto;
        line-height: 1.67;
        color: #3d3d3d;
    }

    .LocationTitle {
        font-size: 16px;
        font-weight: 600;
        color: #000;
        margin-top: 25px;
    }

    .LocationFormn {
        margin-top:35px;
    }

    .InputContainer {
        position: relative;
        width: 300px;
        margin: 0 auto;
    }

    .InputIcon {
        position: absolute;
        right: 10px;
        top: 7px;
        font-size: 25px;
    }
    .Save {
        padding: 0 42px;
        font-weight: bold;
        margin-top:10px;
        color: #FFFFFF;
        line-height: 40px;
        height: 40px;
        border-radius: 21px;
        background-color: $colorBrand;
    }

    h1 {
        font-size: 22px;
        color: #000;
        font-weight: bold;
        margin:-20px 0 20px 0;

        i {
            margin: 0px 0 -2px 5px;
            color: $colorBrand;
        }
    }
    h2{
        font-size: 20px;
        font-weight: bold;
        color:#000;
        margin:0 auto;
        width:280px;
        letter-spacing: 0.20px;
        line-height: normal;
    }
}

.AddLocationAutocomplete {
    max-width: 350px !important;
    margin: 0 auto;

    input {
        padding: 0 10px!important;
    }

    i {
        display: none;
    }
}

// Desktop css override
@media (min-width: $breakpointDesktop) {

    .Location {
        .LocationBackground {
            margin: -45px 0 0 -35px;
        }

        .ContentContainer {
            margin-top: 240px;
        }
        .InputContainer {
            width: 350px;
        }
    }

}
