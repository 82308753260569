@import "src/styles/_colors.scss";

.Switch {
    width: 56px;
    height: 26px;
    margin: 0 0 0 14px;
    border-radius: 13px;
    background-color: #d8d8d8;
    display: flex;
    align-items: center;
    position: relative;

    .Bubble {
        position: absolute;
        left: 3px;
        width: 20px;
        height: 20px;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14);
        background-color: #ffffff;
        border-radius: 13px;
    }

    &.SwitchSelected {
        background-color: $colorBrand;

        .Bubble {
            position: absolute;
            left: unset;
            right: 3px;
        }
    }

    &.SwitchDisabled {
        cursor: default;
    }
}

.Disabled {
    color: $colorGrey;
}
