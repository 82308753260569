@import "src/styles/_colors.scss";

.PasswordArea {
    width: 100% !important;
}

.Spacer {
    margin-bottom: 30px;
}

.SuccessMessage {
    margin: 20px;
    text-align: center;
    font-weight: bold;
    color: $colorBrand;
}
