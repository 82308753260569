@import "src/styles/_colors.scss";

.Container {
    color: $colorGreyDark;
    box-shadow: 0px -2px 10px -4px rgba(0, 0, 0, 0.9);
    box-sizing: border-box;
    padding:  15px;
    background: #FFF;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999992999;

    animation: slideUp 0.5s ease-out forwards;
    transform: translateY(100%);
}

.Notification {
    display: flex;
    align-items: center;
    i {
        font-size: 32px;
    }
    p {
        padding: 0 10px;
    }
}

.Buttons {
    display: flex;
    justify-content: right;
    button {
        margin: 5px;
    }
}

.DenyButton {
    color: $colorGrey;
    padding: 0 10px;
    margin-right: 15px !important;
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}
