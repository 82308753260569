@import "src/styles/_colors.scss";

.Area {
    display: flex;
    line-height: 32px;
    font-size: 14px;
    font-weight: 600;

    .Circle {
        width: 32px;
        height: 32px;
        color: $colorWhite;
        border: 2px solid #ebebeb;
        border-radius: 100px;
        margin-left: 10px;
        justify-content: center;
        display: flex;

        i {
            display: none;
            margin: 3px 0 0 1px;
            font-size: 24px;
        }
    }

    .Checked {
        background: #e95497;
        border-color: #e95497 !important;

        i {
            display: block;
        }
    }

    .CheckedMale {
        background: $colorGreyDark;
        border-color: $colorGreyDark !important;

        i {
            display: block;
        }
    }
}
