@import "src/styles/_colors.scss";

.Age {
    width:100%;
    display: block;

    .AgeBackground {
        margin: 0 auto 35px;
        width:150px;
        text-align: center;
        z-index: 1;
        position: relative;
        img {
            width:150px;
            border-radius: 50%;
        }

        .QuestionMark {
            width: 60px;
            border: 15px solid white;
            background-color: white;
            position: absolute;
            right: -33px;
            bottom: -30px;
        }
    }

    .ContentContainer {
        text-align: center;
    }

    .SimpleText {
        font-size: 15px;
        width:290px;
        margin: 5px auto;
        line-height: 1.67;
        color: #3d3d3d;
    }

    .AgeFormn {
        margin-top:25px;
    }

    .InputContainer {
        position: relative;
        max-width: 300px;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;

        .DateYear {
            width: 90px;
        }

        .DateMonth {
            width: 90px;
        }

        .DateDay {
            width: 65px;
        }
    }

    .Save {
        padding: 0 42px;
        font-weight: bold;
        margin-top:20px;
        color: #FFFFFF;
        line-height: 40px;
        height: 40px;
        border-radius: 21px;
        background-color: $colorBrand;
    }

    h2{
        font-size: 20px;
        font-weight: bold;
        color:#000;
        margin:0 auto;
        width:280px;
        letter-spacing: 0.20px;
        line-height: normal;
    }
}

