.Area {
    margin: 5px auto 0 auto;
    width: 100%;
    text-align: center;

    h3 {
        color: #0a0a0a;
        font-size: 18px;
        font-weight: normal;
    }

    h4 {
        color: #559019;
        font-size: 18px;
        font-weight: bold;
    }

    .Icon {
        width: 55px;
    }

    .Camera {
        width: 105px;
    }

    button {
        margin: 15px 0;
    }

    p {
        color: #3d3d3d;
        text-decoration: underline;
        font-size: 14px;
        cursor: pointer;
    }
}
