@import 'src/styles/_colors.scss';
$desktopBackgroundImage: url('../../../assets/landing/background-web.jpg');
$desktopBackgroundImageTf: url('../../../assets/landing/background-web-tf.jpg');

.Center {
    justify-content: center;
}

.Landing {
    width: 100%;
    margin: 0 auto;

    div {
        display: flex;
        color: $colorBlack;
    }

    h2 {
        margin-top:22px;
    }

    h4 {
        font-size: 18px;
        text-align: center;
        font-weight: normal;
        width: 310px;
        margin: -40px auto 30px
    }

    .WebBackground {
        background: $desktopBackgroundImage no-repeat;
        background-size: cover;
        position: absolute;
        height: 780px;
        width: 100%;
    }

    .WebBackgroundTf {
        img {
            height: 780px;
            width: 100%;
            object-fit: cover;
            object-position: top;
            position: absolute;
        }
    }

    .BottomSpacer {
        margin-bottom: 40px;
    }

    .LoginWrapper {
        width: 100%;
        height: 780px;
        padding-right: 150px;
        justify-content: end;
        align-items: center;

        .Login {
            display: block;
            position: relative;
            width: 404px;
            height: 433px;
            padding: 45px;
            border-radius: 15px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11), 0 0 2px 0 rgba(0, 0, 0, 0.28);
            background-color: #fff;

            .Title {
                text-align: center;
                font-size: 26px;
                font-weight: bold;
                margin: 0 0 50px 0;
                width: auto;
            }

            .Or {
                font-size: 16px;
                color: $colorBlack;
                font-weight: bold;
                margin: 14px 0;
            }

            .AuthActions {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }

            .AuthButton {
                width: 140px;
                height: 44px;
                border-radius: 23px;
                background-color: $colorBrand;
                color: #FFF;
                font-size: 18px;
                font-weight: 600;

                &:hover {
                    background-color: $colorBrandHover;
                    color: #FFF;
                }

                &:disabled {
                    background: #eaeaea;
                }
            }

            .AuthButtonGrey {
                background-color: #ebebeb;
                color: $colorGreyDark;

                &:hover {
                    background-color: #dedede;
                    color: $colorBlack;
                }
            }
        }
    }

    .CenterWrapper {
        width:920px;
        margin: 0 auto;
        display: block;
        text-align: center;

        .Row {
            margin: 30px 0;
            justify-content: space-around;

            .Column {
                display: block;
                text-align: left;
                width: 400px;
            }
        }

        .videoPlayImg {
            max-width: 380px;
            border-radius: 5px;

            &:hover {
                opacity: 0.95;
            }
        }

        .SimpleText {
            color: $colorGreyDark;
            font-size: 14px;
            margin-bottom: 50px;
        }

        .SubTitle {
            color: $colorGreyDark;
            font-size: 15px;
            margin-top:20px;
        }

        .VideoContainer {
            position: relative;
            margin: 25px 0 20px 0;

            .VideoPlayBtn {
                background: url(../../../assets/video-play.png);
                height: 42px;
                width: 42px;
                background-size: 100% 100%;
                cursor: pointer;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

