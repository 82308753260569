@import "src/styles/_colors.scss";

.Area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .UserImage {
        width: 84px;
        height: 84px;
        border-radius: 100px;
    }

    .Name {
        margin: 15px 0 23px 0;
        font-size: 24px;
        font-weight: bold;
        display: flex;
        align-items: center;

        .Verified {
            margin-left: 5px;
        }
    }

    .Text {
        margin-bottom: 15px;
        font-weight: 600;
    }

    .VipText {
        text-decoration: underline;
        cursor: pointer;
        font-size: 16px;
        color: $colorBlack;
    }

    .BigText {
        font-size: 16px;
        font-weight: bold;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .CenterText {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 35px;
    }

    .VipUser {
        width: 320px;
        height: 98px;
        background-color: $colorWhite;
        border: 1px solid #ffbe45;
        border-radius: 5px;
        box-shadow: 0 0 10px 1px rgba(250, 185, 66, 0.9);
        margin-bottom: -18px;
        z-index: 3;

        .LeftSide {
            margin: 7px 18px 0 10px;
            position: relative;
            float: left;

            .UserImg {
                border-radius: 100px;
                width: 70px;
                height: 70px;
                padding: 2px;
                border: 2px solid #ffbe45;
            }

            .VipArea {
                height: 20px;
                width: 20px;
                position: absolute;
                right: 2px;
                bottom: 6px;

                img {
                    width: 100%;
                }
            }
        }

        .RightSide {
            position: relative;
            width: 100%;

            .VipName {
                margin: 20px 0 2px 0;
                font-size: 18px;
                font-weight: bold;
            }
        }
    }

    .ConversationBackground {
        img {
            width: 248px;
            margin-bottom: 12px;
        }
    }

    .IconConversation {
        margin-top: 80px;
        img {
            width: 60px;
        }
    }

    .DiamondButton {
        padding: 5px 31px !important;
        border-radius: 21px;
        font-size: 16px;
        background-image: linear-gradient(100deg, #fdbb36, #ff7632);
        cursor: pointer;
        transition: 0.1s ease all;
        a {
            background-image: linear-gradient(100deg, #fdbb36, #ff7632);
            font-weight: bold;
        }
        &:hover {
            box-shadow: 0 0 10px -2px #666
        }
    }

    .AreaActions {
        max-width: 490px;
        width: 100%;
    }

    .Textarea {
        width: 100%;
        min-height: 133px;
        margin-bottom: 43px;
        padding: 10px 15px;
        background-color: #FFF;
        color: $colorGreyDark;
        caret-color: $colorGreyDark;
        resize: none;
        box-sizing: border-box;

        &:focus {
            background-color: #f6f6f6;
            border: solid 1px #eaeaea !important;
        }
    }

    

    .Actions {
        width: 100%;
        display: flex;
        flex-direction: column;
        .SendArea {
            margin-left: auto;
            display: flex;
            align-items: center;

            font-size: 17px;
            font-weight: bold;

            letter-spacing: 0.25px;

            color: $colorBrand;

            i {
                margin-left: 9px;
                font-size: 24px;
            }

        }
        .SubmitBtn {
            margin-top: 25px;
            font-size: 17px;
            font-weight: bold;
            letter-spacing: 0.25px;
            color: $colorBrand;
        }
        .EmailText {
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            margin-bottom: 14px;
            margin-top: -15px;
        }

        .Submit {
            margin-top: 25px;
            margin-left: unset;
        }

        input {
            box-sizing: border-box;
        }

        .Error {
            border: 1px solid $colorError;
        }
        .EmailExists {
            color: $colorError;
            margin-bottom: 5px;;
        }
    }
    
    .SuccessText {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 10px;
        margin-top: 35px;
        color: $colorBrand;
    }

}
