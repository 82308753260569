@import "mixins/placeholder";
@import "_colors.scss";
@import "checkbox.scss";

input {
    font-size: inherit;
    font-family: inherit;
    height: 34px;
    padding: 0 10px;
    line-height: 22px;
    border-radius: 6px;
    border: solid 1px $colorGreyLight;
    background-color: $colorWhite;
    color: $colorBlack;

    &:focus {
        outline: none !important;
        border-color: $colorGrey !important;
    }

    @include placeholder {
        color: $colorGrey;
    }
}

textarea {
    font-size: inherit;
    font-family: inherit;
    padding: 10px;
    border-radius: 6px;
    border: solid 1px $colorGreyLight;
    background-color: $colorWhite;
    color: $colorBlack;

    &:focus {
        outline: none !important;
        border-color: $colorGrey !important;
    }

    @include placeholder {
        color: $colorGrey;
    }
}

.Button {
    font-size: inherit;
    font-family: inherit;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.26px;
    color: $colorWhite;
    display: flex;
    align-content: center;
    align-items: center;

    border-radius: 6px;
    background-color: $colorBrand;
    height: 36px;
    padding: 0 12px;

    &:hover {
        color: #FFF;
        background: $colorBrandHover;
    }

    &.Black {
        background: $colorGreyDark;
        font-size: 14px;
        font-weight: 600;

        &:hover {
            color: $colorGreyLight;
        }
    }
}

.ButtonRounded {
    background: $colorBrand;
    color : $colorWhite;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.12px;
    line-height: 33px;
    padding: 0 17px;
    border-radius: 16px;

    &:hover {
        color: $colorWhite;
        background: $colorBrandHover;
    }
}

.ButtonRoundedBig {
    font-size: 16px !important;
    line-height: 44px !important;
    border-radius: 28px !important;
    padding: 0 31px !important;
}

.ButtonRoundedDiamond {
    padding: 5px 31px !important;
    border-radius: 21px;
    background-image: linear-gradient(100deg, #fdbb36, #ff7632);
    cursor: pointer;
    transition: 0.1s ease all;
    &:hover {
        box-shadow: 0 0 10px -2px #666;
        background-image: linear-gradient(100deg, #fdbb36, #ff7632);
    }
}

.ButtonRoundedVip {
    padding: 5px 31px !important;
    border-radius: 21px;
    background-image: linear-gradient(278deg, #ff7676, #f54ea2);

    &:hover {
        background-image: linear-gradient(278deg, #ff7676, #f54ea2);
    }
}

select {
    color: $colorGreyDark;
    font-weight: 600;
    height: 34px;
    line-height: 22px;
    padding: 0;

    appearance: none;
    background-color: transparent;
    border: none;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;

    z-index: 1;

    &::-ms-expand {
        display: none;
    }

    // Remove focus outline, will add on alternate element
    outline: none;
}

.select {
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    position: relative;
    font-size: 14px;
    padding: 0 10px;

    select,
    &::after {
        grid-area: select;
    }

    border: 1px solid $colorGreyLight;
    border-radius: 6px;
    cursor: pointer;
    background-color: $colorGreyLight;
    height: 36px;

    .selectMore {
        position: absolute;
        top: 8px;
        right: 6px;
        color: $colorGreyDark;
    }

    // Custom arrow
    //&::after {
    //    content: "";
    //    justify-self: end;
    //    width: 0.8em;
    //    height: 0.5em;
    //    background-color: $colorGreyDark;
    //    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    //}
}

// Interim solution until :focus-within has better support
select:focus + .focus {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: 0;
    border: 1px solid $colorGreyDark;
    border-radius: inherit;
}

.select--disabled {
    cursor: not-allowed;
    background-color: #eee;
    background-image: linear-gradient(to top, #ddd, #eee 33%);
}

// Select Override for Registration select
.SelectRegistration {
    background: #FFF !important;
    height: 44px !important;
    line-height: 44px !important;

    select {
        height: 44px !important;
    }

    .selectMore {
        top: 0;
        line-height: 44px;
    }
}


// Select Override for Registration select
.SelectWhite {
    background: #FFF !important;
    height: 46px !important;
    line-height: 46px !important;
    font-weight: normal !important;

    select {
        height: 46px !important;
    }

    // Interim solution until :focus-within has better support
    select:focus + .focus {
        border: 1px solid $colorGrey !important;
        border-radius: inherit;
    }

    .selectMore {
        top: 0;
        right: 15px;
        font-size: 11px;
        line-height: 46px;
    }
}
