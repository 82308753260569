@import "../../styles/Mixins";
$headerHeight: 68px;

.HeaderSpacer {
  height: $headerHeight;
  display: block;
}

.Container {
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $headerHeight;
  background: #000;
}

.Wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  padding: 0 16px;
}

.Logo {
  width: 126px;
  height: 18px;

  @include md {
    width: 154px;
    height: 22px;
  }
}
