@import "src/styles/_colors.scss";

.Pref {
    width: 100%;
    display: block;
    text-align: center;

    h1 {
        height: 24px;
        margin: 12px 0;
        font-size: 18px;
        font-weight: bold;
    }

    .Introduction {
        color: $colorGreyDark;
    }

    .Genders {
        display: flex;
        justify-content: center;
        margin: 40px 0 25px 0;

        .Option {
            width: 100px;
            margin: 0 20px;

            img {
                display: block;
                margin: 0 auto 20px auto;
            }

            .CheckBoxArea {
                display: flex;
                align-items: center;
                justify-content: space-around;

                .Checkbox {
                    box-sizing: border-box;

                    .Circle {
                        color: $colorWhite;
                        width: 32px;
                        height: 32px;
                        border: 2px solid #ebebeb;
                        border-radius: 100px;

                        display: flex;
                        align-items: center;
                    }

                    .Checked {
                        background: #e95497;
                        border-color: #e95497 !important;

                        i {
                            font-size: 26px;
                            margin-left: 2px;
                        }
                    }

                    &.Male {
                        .Checked {
                            background: $colorGreyDark;
                            border-color: $colorGreyDark !important;
                        }
                    }
                }
            }
        }
    }

    .AgeTitle {
        font-weight: 600;
        margin-bottom: 16px;
    }

    .SliderArea {
        display: flex;
        align-items: center;
        height: 88px;
    }

    .SaveArea {
        width: 100%;
        margin: 0 auto;

        .Save {
            padding: 0 42px;
            font-weight: bold;
            color: $colorWhite;

            line-height: 42px;
            border-radius: 21px;
            background-color: $sidebarColorPurple;
        }
    }
}
