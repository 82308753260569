.Button {
  color: #000;

  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 0 34px;
  height: 44px;
  display: flex;
  align-items: center;
  text-decoration: none;

  border-radius: 66px;
  border: 0;
  background: var(--Gold, #EACCA9);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.10);

  &:hover {
    cursor: pointer;
    background: #E1B98C;
  }

  &.ButtonHeader {
    height: 35px !important;
    padding: 0 22px;
    background: #FFF;
    text-transform: none !important;
    font-size: 12px !important;
    font-family: 'Poppins', sans-serif;
    font-weight: 500 !important;

  }
}

