$isTravelfriend: true;

$colorBlack: #000000;
$colorWhite: #FFFFFF;



$colorGrey: #868686;
$colorGreyLight: #e4e4e4;
$colorGreyVeryLight: #f6f6f6;
$colorGreyDark: #3d3d3d;
//$colorGreen: #1d38a5;
$colorGreen: #61a51d;
$colorGreenHover: #559019;
$colorError: #d81656;
$colorDiamond: #fa648a;
$borderGreyLight: #d8d8d8;
$colorWarning: #f1632e;

//$sidebarItemBackround: #e9f2df;
//$sidebarColorGreen: #61a51d;
$sidebarColorPurple: #920e99;
$sidebarColorPurpleHover: #740b79;

$favoritedColor: #ff8e00;
$newMessageGreen: rgba(97, 165, 29, 0.5);


// Widths
$widthDesktopMax: 1440px;
$widthDesktopContent: 1180px;
$widthDesktopSidebarContent: 880px;



// Breakpoints
$breakpointDesktop: 1180px;
//Travel girls
//$sidebarColorBrand: #61a51d;
//$sidebarItemBackgroundBrand: #e9f2df;

//Travel friend:
$colorBrand: #61a51d;
$colorBrandLighter: #b6cf9b;
$sidebarColorBrand: #61a51d;
$sidebarItemBackgroundBrand: #e9f2df;
$colorBrandHover: #559019;


$tgButterfly: url('/assets/buttefly.svg');

@if $isTravelfriend {
    $colorBrand: #2189e6;
    $colorBrandLighter: #a5cbee;
    $colorBrandHover: #186db7;
    $sidebarColorBrand: #2189e6;
    $sidebarItemBackgroundBrand: #cce5fa;
}

$colorOnline: #61a51d;

//@else {
//    $sidebarColorBrand: #1d38a5;
//    $sidebarItemBackgroundBrand: #dfe3f2;
//}

$desktopBackgroundImage: url('../../../assets/landing/luke-dahlgren-350329.jpg');

@if $isTravelfriend {
    $desktopBackgroundImage: url('../../../assets/landing/luke-dahlgren-350329.jpg');
    //$desktopBackgroundImage: url('../../../assets/landing/background-web.jpg');
}
//@else
//{
//    $desktopBackgroundImage: url('../../../assets/landing/background-web.jpg');
//}
