@import "../../styles/Mixins";
$headerHeight: 66px;

.HeaderSpacer {
  height: $headerHeight;
  display: block;
}

.Container {
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $headerHeight;
  background: #fff;
}

.Wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  padding: 0 20px;

  @include md {
    padding: 0 10%;
  }
}

.Logo {
  width: 126px;
  height: 18px;

  @include md {
    width: 154px;
    height: 22px;
  }
}

.ButtonHeaderLight {
  background: #B77431 !important;
  color: #FFFFFF !important;
}
