@import "../styles/Mixins";

.Root {
    margin-bottom:64px;
    display: flex;
    flex-direction: row;

    @include md {
        margin-bottom: 0;
        padding: 0 100px;
    }
}

.ImageItem {
    flex: 1;
    max-width: 33%;
    margin: 0 1px;
    img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}
