@import "src/styles/_colors.scss";

.Backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 0;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.21);
}

.Modal {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.ModalWrapper {
    background-color: transparent;
    height: 100%;
    text-align: center;
    font-size: 0;
    padding: 10px;

    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
}

.ModalWindow {
    display: inline-block;
    position: relative;
    margin: 20px auto;
    padding-bottom: 20px;
    font-size: 1rem;
    vertical-align: middle;
    text-align: left;

    max-width: 600px;
    width: 100%;
}

.ModalContainer {
    background: $colorWhite;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11), 0 0 2px 0 rgba(0, 0, 0, 0.28);
    border-radius: 15px;
    position: relative;

    display: flex;
    flex-direction: column;
    padding: 35px;
}

.CloseButton {
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 18px;
    cursor: pointer;
}

.VideoModal {
    .ModalWindow {
        max-width: 640px !important;
    }

    .ModalContainer {
        background: none !important;
        padding: 0 !important;
        box-shadow: none !important;
    }

    .CloseButton {
        display: none;
    }
}

@media (max-width: $breakpointDesktop) {
    .Backdrop {
        background: rgba(0, 0, 0, 0.75) !important;
    }
}
